/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
#mobile-navi.cb-toggle-target-active {
  max-height: 800px;
}
#hj-contenttop,
#hj-contentbottom {
  margin-left: -1.5%;
  width: 103%;
}
.hj-part {
  width: 47%;
  margin-left: 1.5%;
  margin-right: 1.5%;
}
.hj-part img {
  margin-left: -235px;
  width: 470px;
}
.hj-part.half img {
  width: 727px;
  margin-left: -363px;
}
.hj-title {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  padding-bottom: 20px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.70940171%;
  margin-left: 1.70940171%;
}
.area .part,
.area > .grid table {
  margin-right: 1.70940171%;
  margin-left: 1.70940171%;
  width: 96.58119658%;
}
.area .tiny {
  width: 46.58119658%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.41880342%;
  margin-left: 3.41880342%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 93.16239316%;
}
.area > .slim .tiny {
  width: 93.16239316%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 2.47933884%;
  margin-left: 2.47933884%;
}
.base .part,
.base > .grid table {
  margin-right: 2.47933884%;
  margin-left: 2.47933884%;
  width: 95.04132231%;
}
.base > .slim {
  width: 50%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 4.95867769%;
  margin-left: 4.95867769%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 90.08264463%;
}
.base > .slim .tiny {
  width: 90.08264463%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  margin-left: -1.73913043%;
  width: 103.47826087%;
}
.base {
  margin-left: -2.60869565%;
  width: 105.2173913%;
}
.base div.wide {
  width: 50%;
}
.base div.wide div.head h2 {
  width: 90.08264463%;
  margin-left: 4.95867769%;
  margin-right: 4.95867769%;
}
.base div.wide div.tall {
  width: 90.08264463%;
  margin-left: 4.95867769%;
  margin-right: 4.95867769%;
}
.base div.wide div.tiny {
  width: 90.08264463%;
  margin-left: 4.95867769%;
  margin-right: 4.95867769%;
}
div.main div.seam {
  width: 96.58119658%;
  margin-left: 1.70940171%;
  margin-right: 1.70940171%;
}
@media only screen and (max-width: 1000px) {
  div.base div.slim div.pict img {
    margin: 0 auto;
    max-width: 186px !important;
  }
}
div.base div.seam {
  width: 95.04132231%;
  margin-left: 2.47933884%;
  margin-right: 2.47933884%;
}
h1 {
  font-size: 40px;
}
.hj-part .fadetext {
  font-size: 22px;
}
.hj-part .imgtext {
  font-size: 26px;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop,
#disp.cb-shoppingcart {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */